import * as React from 'react';
import Layout from '../components/layouts/layout';

const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <div
        className="
            w-full
            sm:px-10
            sm:max-w-screen-sm"
      >
        <ul className="break-words ml-4 sm:ml-8">
          <li>
            ocknamo
            <ul className="ml-4 sm:ml-8">
              <li>
                <a
                  href="https://github.com/ocknamo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  github
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ocknamo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  twitter
                </a>
              </li>
              <li>
                <a
                  href="https://zenn.dev/ocknamo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zenn
                </a>
              </li>
            </ul>
          </li>
          <li>
            技術スタック
            <ul className="ml-4 sm:ml-8">
              <li>Typescript</li>
              <li>Javascript</li>
              <li>Python3</li>
              <li>Angular</li>
              <li>Nestjs</li>
            </ul>
          </li>
          <li>
            寄付
            <ul className="ml-4 sm:ml-8">
              <li>BTC: 1MupdofmXC8vrRLLJQBKq6NLAp1sRmfAqt</li>
              <li>MONA: MQDC9ELzosBUZ5vov8RNAqavTnsV42V1cu</li>
            </ul>
          </li>
          <li>
            このブログの構成
            <ul className="ml-4 sm:ml-8">
              <li>Gatsby</li>
              <li>Typescript</li>
              <li>tailwindcss</li>
              <li>Eslint</li>
              <li>Prettier</li>
              <li>mdx</li>
              <li>GitHub Actions</li>
              <li>Cloudflare</li>
            </ul>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default IndexPage;
